import React, { useState } from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"

// Begin swiper
import SwiperCore, { Pagination, Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import RichContentStructuredText from "../richContentStructuredText"
import { ChevronLeft, ChevronRight } from "react-feather"
import { MagicLink } from "../../utils/magicLink"
import LongArrowRight from "../LongArrowRight"

SwiperCore.use([Navigation, Mousewheel, Pagination, A11y])
// End swiper

const ItemCarousel = ({ title, items, link }) => {
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  // console.log(items)
  return (
    <Container
      variant="fw"
      sx={{
        height: "100%",
      }}
    >
      <Grid
        gap={[0, 0, 0]}
        columns={["1fr", "calc(50vw - 640px) 1fr"]}
        sx={{
          px: [0, 0],
          "grid-template-rows": ["auto", ".8fr 1.2fr"],
          height: "100%",
        }}
      >
        <Box sx={{ display: ["none", "block"] }}></Box>
        <Grid columns={[1,".8fr .2fr"]} sx={{ alignItems: "center", justifyContent:"space-between" }}>
          <Heading
            variant="h2"
            as="h2"
            sx={{ color: "dark", width: ["100%", "80%", "60%", "60%"] }}
          >
            {title}
          </Heading>
          {link && (
            <Box sx={{ my: [4] }}>
              <MagicLink
                item={link}
                sx={{
                  color: "dark",
                  textDecoration: "none",
                  textTransform: "uppercase",
                }}
              >
                <Flex sx={{ alignItems: "center" }}>
                  <LongArrowRight color="dark" />
                  <Box
                    sx={{
                      ml: 3,
                      color: "dark",
                    }}
                  >
                    {link.anchor}
                  </Box>
                </Flex>
              </MagicLink>
            </Box>
          )}
        </Grid>
        <Box sx={{ display: ["none", "block"] }}></Box>
        <Box>
          <Box>
            <Swiper
              spaceBetween={16}
              grabCursor={true}
              centeredSlidesBounds={true}
              navigation={{ prevEl, nextEl }}
              style={{ height: "100%" }}
              id={"mySwiper" + title.replace(/[^A-Z0-9]/gi, "_")}
              breakpoints={{
                320: {
                  slidesPerView: 1.2,
                },
                640: {
                  slidesPerView: 2.2,
                },
                896: {
                  slidesPerView: 2.2,
                },
                1366: {
                  slidesPerView: 3.2,
                },
              }}
            >
              {items.map((item, index) => (
                <SwiperSlide key={item.originalId}>
                  <Item richContent={item} index={index} />
                </SwiperSlide>
              ))}
            </Swiper>
            <Flex sx={{ alignItems: "center" }}>
              <Box as="div" ref={node => setPrevEl(node)} sx={{ mr: ["-2px"] }}>
                <Flex
                  sx={{
                    p: [2],
                    border: "2px solid",
                    borderColor: "dark",
                    cursor: "pointer",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <ChevronLeft />
                </Flex>
              </Box>
              <Box as="div" ref={node => setNextEl(node)}>
                <Flex
                  variant="svg.arrow.dark"
                  sx={{
                    p: [2],
                    border: "2px solid",
                    borderColor: "dark",
                    cursor: "pointer",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <ChevronRight />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Grid>
    </Container>
  )
}

const Item = ({ richContent, index }) => (
  <Flex
    sx={{
      mx: [2],
      ml: index === 0 && 0,
      flexDirection: "column",
      border: "1px solid",
      borderColor: "primary",
      padding: [4],
      overflow: "hidden",
      "&:hover": {
        backgroundColor: "primary",
        color: "light",
      },
      minHeight: ["100%", "400px"],
    }}
  >
    <Flex sx={{ minHeight: ["120px", "120px", "120px", "120px"] }}>
      <Heading as="h3" variant="h4" sx={{ color: "inherit", mt: 0 }}>
        {richContent.title}
      </Heading>
    </Flex>

    <Text>{richContent.subtitle}</Text>
  </Flex>
)

export default ItemCarousel
