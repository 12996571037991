import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { InboundLink } from "../link"
import { getPagePath } from "../../utils/path"
import { MagicLink } from "../../utils/magicLink"
import LongArrowRight from "../LongArrowRight"
import BlockParallax from "../blockParallax"

const ImageAndText = ({ link, title, body, image, kicker, subtitle }) => {
  return (
    <Container
      variant="fw"
      sx={{ height: "100vh", position: "relative", height: "100%" }}
    >
      <Grid
        columns={[1, 1, 1, "calc(50vw - 640px) 1fr"]}
        gap={[0, 0, 0]}
        sx={{ py: [0, 7, 7,7], pb:[6,7,7,7], px: [0, 0, 0], position: "relative" }}
      >
        <Box></Box>
        <Grid
          columns={[1, 1, 1, "1fr 1fr"]}
          gap={[0, 0, 32]}
          sx={{ py: [0, 0, 0,7], position: "relative" }}
        >
          <Flex
            sx={{
              flexDirection: "column",
              pr: [0, 0, 0, 8],
              py: [0,0,0,7],
            }}
          >
            <Box>
              <Text color="">{kicker}</Text>
              <Heading as="h2" variant="h3" color="primary" sx={{ mb: [0,4,4,6], }}>
                {title}
              </Heading>
            </Box>
            {image && (
              <Box
                sx={{
                  display:["block","block","block","none"],
                  position: "relative",
                  top: 0,
                  left: 0,
                  my:[4,4,4,0],
                  height: ["100%"],
                  width: "100%",
                  ".gatsby-image-wrapper": {
                    height: ["100%"],
                    width: "100%",
                  },
                  overflow: "hidden",
                }}
              >
                <BlockParallax
                  style={{ width: "100%", height: "115%" }}
                  translateY={[-20, 0]}
                >
                  <GatsbyImage image={image.gatsbyImageData} alt={image.gatsbyImageData} />
                </BlockParallax>
              </Box>
            )}
            <Box sx={{ p: { color: "text" }, mb: [5] }}>
              <RichContentStructuredText text={body} theme="dark" />
            </Box>
            <MagicLink item={link} sx={{ textDecoration: "none" }}>
              <Flex sx={{ alignItems: "center" }}>
                <LongArrowRight />
                <Box sx={{ ml: 3, color: "dark" }}>{link.anchor}</Box>
              </Flex>
            </MagicLink>
          </Flex>
          {image && (
            <Box
              sx={{
                display:["none","none","none","block"],
                position: "relative",
                top: 0,
                left: 0,
                height: ["100%"],
                width: "100%",
                ".gatsby-image-wrapper": {
                  height: ["100%"],
                  width: "100%",
                },
                overflow: "hidden",
              }}
            >
              <BlockParallax
                style={{ width: "100%", height: "115%" }}
                translateY={[-20, 0]}
              >
                <GatsbyImage image={image.gatsbyImageData} alt={image.gatsbyImageData} />
              </BlockParallax>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ImageAndText
