import React, { useState } from "react"
import { Box, Container, Flex, Heading, Text } from "@theme-ui/components"

// Begin swiper
import SwiperCore, { Pagination, Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import { InboundLink } from "../link"
import { getPagePath } from "../../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { ChevronLeft, ChevronRight } from "react-feather"
import BlockParallax from "../blockParallax"

SwiperCore.use([Navigation, Mousewheel, Pagination, A11y])
// End swiper

const PageCarousel = ({ title, pages }) => {
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)
  return (
    <Box sx={{ height: "100vh", position: "relative" }}>
      <Container
        variant="header"
        sx={{
          display: "flex",
          paddingX: [3, 4],
          top: [8],
          alignItems: "center",
          position: "absolute",
          justifyContent: "space-between",
          zIndex: 2,
        }}
      >
        <Box>
          <Heading>{title}</Heading>
        </Box>
        <Flex>
          <Box as="div" ref={node => setPrevEl(node)} sx={{ mr: ["-2px"] }}>
            <Flex
              sx={{
                p: [2],
                border: "2px solid",
                borderColor: "light",
                cursor: "pointer",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <ChevronLeft color="white" />
            </Flex>
          </Box>
          <Box as="div" ref={node => setNextEl(node)}>
            <Flex
              sx={{
                p: [2],
                border: "2px solid",
                borderColor: "light",
                cursor: "pointer",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <ChevronRight color="white" />
            </Flex>
          </Box>
        </Flex>
      </Container>
      <Box
        sx={{
          height: "100%",
          zIndex: 0,
          ".swiper-wrapper": {
            minHeight: "100%",
          },
          ".swiper-container": {
            height: "100%",
            pb: [0],
          },
          ".swiper-slide": {
            height: "100% !important",
          },
        }}
      >
        <Swiper
          slidesPerView={4}
          grabCursor={true}
          breakpoints={{
            320: {
              slidesPerView: 1.2,
            },
            640: {
              slidesPerView: 2.2,
            },
            896: {
              slidesPerView: 2.2,
            },
            1366: {
              slidesPerView: 3.2,
            },
          }}
          navigation={{ prevEl, nextEl }}
        >
          {pages.map(page => (
            <SwiperSlide key={page.originalId}>
              <PageThumb page={page} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

const PageThumb = ({ page }) => (
  <InboundLink
    to={getPagePath(page, page.locale)}
    sx={{
      textDecoration: "none",
      height: "100%",
      display: "block",
      position: "relative",
    }}
  >
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: ["100%"],
        width: "100%",
        zIndex: -1,
        backgroundColor: !page.banner && "dark",
        ".gatsby-image-wrapper": {
          height: ["100%"],
          width: "100%",
          img: {
            objectPosition: "60% 50%",
          },
        },
        overflow: "hidden",
      }}
    >
      {page.banner && (
        <BlockParallax
          style={{ width: "100%", height: "115%" }}
          translateY={[-20, 0]}
        >
          <GatsbyImage image={page.banner.gatsbyImageData} alt={page.banner.gatsbyImageData} />
        </BlockParallax>
      )}
    </Box>
    <Flex
      sx={{
        height: ["100%"],
        padding: 4,
        alignItems: "center",
      }}
    >
      <Box>
        <Box sx={{ minHeight: ["100px", "200px"] }}>
          <Heading as="h3" variant="h4" sx={{ color: "light", mt: 0, hyphens: "auto" }}>
            {page.title}
          </Heading>
        </Box>
      </Box>
    </Flex>
  </InboundLink>
)

export default PageCarousel
