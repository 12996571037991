import React, { useEffect, useState } from "react"
import { Box, Text, Heading, Flex, Container, Grid } from "@theme-ui/components"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"
import { GatsbyImage } from "gatsby-plugin-image"
import BlockParallax from "../blockParallax"
import { MagicLink } from "../../utils/magicLink"
import LongArrowRight from "../LongArrowRight"
import { useBreakpointIndex } from "@theme-ui/match-media"

const NumbersCollection = ({
  kicker,
  title,
  description,
  link,
  image,
  numbers,
  video,
  first,
}) => {
  const breakpointIndex = useBreakpointIndex()
  return (
    <Box
      sx={{
        minHeight: ["100vh"],
        height: ["auto", "100%"],
        position: "relative",
        pt: [first && "72px", 0],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: ["none", "block"],
          position: "absolute",
          top: 0,
          left: 0,
          height: ["100%"],
          width: "100%",
          zIndex: image ? -1 : 0,
          backgroundColor: !image && "dark",
          ".gatsby-image-wrapper": {
            height: ["100%"],
            width: "100%",
          },
          video: {
            height: "100%",
            width: "100%",
            objectFit: "cover",
          },
          ":before": {
            content: "''",
            position: "absolute",
            background: "rgba(0, 0, 0, .5)",
            borderRadius: "5px",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1,
          },
          overflow: "hidden",
        }}
      >
        {!video && image && image.gatsbyImageData && (
          <BlockParallax
            style={{ width: "100%", height: "115%" }}
            translateY={[-20, 0]}
          >
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.gatsbyImageData}
            />
          </BlockParallax>
        )}

        {video && (
          <BlockParallax
            style={{ width: "100%", height: "115%" }}
            translateY={[-20, 0]}
          >
            <video
              title={"background-video"}
              id=""
              class=""
              autoplay="autoplay"
              muted="muted"
              loop="loop"
              playsinline="playsinline"
            >
              <source src={video.url} type="video/mp4" />
            </video>
          </BlockParallax>
        )}
      </Box>

      <Box
        sx={{
          position: "relative",
          display: ["block","none"],
          ".gatsby-image-wrapper": {
            height: ["100%"],
            py: [10],
            width: "100%",
          },
          "img,video": {
            display:"block",
            width: "100%",
            height: "100%",
          },
        }}
      >
        {!video && image && image.gatsbyImageData && (
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.gatsbyImageData}
          />
        )}

        {video && (
          <Box
            sx={{
              position: "relative",
              ":before": {
                content: "''",
                position: "absolute",
                background: "rgba(0, 0, 0, .5)",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 1,
              },
            }}
          >
            <video
              title={"background-video"}
              id=""
              class=""
              autoplay="autoplay"
              muted="muted"
              loop="loop"
              playsinline="playsinline"
            >
              <source src={video.url} type="video/mp4" />
            </video>
          </Box>
        )}

        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            py: 4,
            zIndex:4,
          }}
        >
          <Container>
            <Box sx={{ display: ["block", "none"] }}>
              <Heading
                as={first ? "h1" : "h2"}
                variant="h3"
                sx={{ mb: [4, 7], color: ["light"] }}
              >
                {title}
              </Heading>
            </Box>
            {/* </BlockParallax> */}
            <Box sx={{ display: [!first ? "block" : "none", "none"] }}>
              <Box>
                <Text
                  sx={{ color: ["light"], lineHeight: "1.33" }}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Box>
              {link && (
                <Box sx={{ display: ["block", "none"], my: [4] }}>
                  <MagicLink
                    item={link}
                    sx={{
                      color: ["light"],
                      textDecoration: "none",
                      textTransform: "uppercase",
                    }}
                  >
                    <Flex sx={{ alignItems: "center" }}>
                      <LongArrowRight color={"light"} />
                      <Box
                        sx={{
                          ml: 3,
                          color: ["light"],
                        }}
                      >
                        {link.anchor}
                      </Box>
                    </Flex>
                  </MagicLink>
                </Box>
              )}
              {link && (
                <Box sx={{ display: ["none", "block"], my: [4] }}>
                  <MagicLink
                    item={link}
                    sx={{
                      color: ["dark", "light"],
                      textDecoration: "none",
                      textTransform: "uppercase",
                    }}
                  >
                    <Flex sx={{ alignItems: "center" }}>
                      <LongArrowRight
                        color={breakpointIndex > 0 ? "light" : "dark"}
                      />
                      <Box
                        sx={{
                          ml: 3,
                          color: ["dark", "light"],
                        }}
                      >
                        {link.anchor}
                      </Box>
                    </Flex>
                  </MagicLink>
                </Box>
              )}
            </Box>
          </Container>
        </Box>
      </Box>

      <Container
        sx={{
          height: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: 1,
        }}
      >
        <Grid
          columns={[1, "1fr 1fr"]}
          gap={[0, 10]}
          sx={{ alignItems: "center", height: "100%", mt: first && [0, 7, 10] }}
        >
          <Box>
            {kicker && (
              <Box>
                <Text as="div" variant="sectionTitle">
                  <span>{kicker}</span>
                </Text>
              </Box>
            )}
            {/* <BlockParallax
              translateY={[5, -5]}
            > */}
            <Box sx={{ display: ["none", "block"] }}>
              <Heading
                as={first ? "h1" : "h2"}
                variant="h3"
                sx={{ mb: [4, 7], color: ["dark", "light"] }}
              >
                {title}
              </Heading>
            </Box>
            {/* </BlockParallax> */}
            <Box sx={{ display: [first ? "block" : "none","block"] }}>
              <Box>
                <Text
                  sx={{ color: ["dark", "light"], lineHeight: "1.33" }}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Box>
            </Box>

            {link && (
              <Box sx={{ display: [first ? "block": "none", "none"], my: [4] }}>
                <MagicLink
                  item={link}
                  sx={{
                    color: ["dark", "light"],
                    textDecoration: "none",
                    textTransform: "uppercase",
                  }}
                >
                  <Flex sx={{ alignItems: "center" }}>
                    <LongArrowRight
                      color={breakpointIndex > 0 ? "light" : "dark"}
                    />
                    <Box
                      sx={{
                        ml: 3,
                        color: ["dark", "light"],
                      }}
                    >
                      {link.anchor}
                    </Box>
                  </Flex>
                </MagicLink>
              </Box>
            )}
            {link && (
              <Box sx={{ display: ["none", "block"], my: [4] }}>
                <MagicLink
                  item={link}
                  sx={{
                    color: ["dark", "light"],
                    textDecoration: "none",
                    textTransform: "uppercase",
                  }}
                >
                  <Flex sx={{ alignItems: "center" }}>
                    <LongArrowRight
                      color={breakpointIndex > 0 ? "light" : "dark"}
                    />
                    <Box
                      sx={{
                        ml: 3,
                        color: ["dark", "light"],
                      }}
                    >
                      {link.anchor}
                    </Box>
                  </Flex>
                </MagicLink>
              </Box>
            )}
          </Box>
          <Grid sx={{ "grid-template-rows": `${numbers.length}fr` }} gap={[0]}>
            {numbers.map((number, index) => (
              <Number number={number} />
            ))}
          </Grid>
        </Grid>
        {first && (
          <Flex
            sx={{
              alignItems: "center",
              mt: [5, 5, 7, 7],
              transform: "rotate(90deg)",
            }}
          >
            <LongArrowRight color={breakpointIndex > 0 ? "light" : "dark"} />
          </Flex>
        )}
      </Container>
    </Box>
  )
}

const Number = ({ number }) => {
  const [startCount, setStartCount] = useState(false)
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView === true && startCount === false) {
      setStartCount(true)
    }
  }, [inView])

  return (
    <Grid
      columns={[".2fr .8fr", number.float === 999 ? ".1fr .8fr" : ".2fr .8fr"]}
      ref={ref}
      gap={[0]}
      sx={{
        px: [3, 4],
        py: [3, number.float === 999 ? 4 : 4],
        backgroundColor: ["light", "rgba(0,0,0,.2)"],
        mb: "-2px",
        "&:hover": {},
        border: "2px solid",
        borderColor: ["dark", "light"],
        alignItems: "center",
      }}
    >
      <Text
        variant="h5"
        sx={{ color: ["dark", "light"], marginTop: 0, marginBottom: 2 }}
      >
        {number.float === 999 ? (
          <Box>+</Box>
        ) : (
          <CountUp
            end={number.float}
            prefix={number.prefix ? `${number.prefix} ` : null}
            start={startCount}
          />
        )}
      </Text>
      <Text
        sx={{
          color: ["dark", "light"],
          textTransform: "uppercase",
          ml: number.float === 999 ? 2 : 3,
          fontWeight: number.float === 999 ? "400" : "bold",
        }}
      >
        {number.suffix}
      </Text>
    </Grid>
  )
}

export default NumbersCollection
