import { Box, Container, Flex, Grid, Text, Image } from "@theme-ui/components"
import React from "react"
import { useCompany } from "../hooks/useCompany"
import { useFooter } from "../hooks/useFooter"
import { MagicLink } from "../utils/magicLink"
import { useSocial } from "../hooks/useSocial"
import { i18nContext } from "../context/i18nContext"
import { Facebook, Instagram, Twitter, Youtube, Linkedin } from "react-feather"
import LongArrowRight from "./LongArrowRight"
import LinkedinWhite from "../images/linkedin-white.png"
import LinkedinBlack from "../images/linkedin-black.png"
import { useBreakpointIndex } from "@theme-ui/match-media"

const ContactFooter = () => {
  const breakpointIndex = useBreakpointIndex()
  const footer = useFooter()
  const company = useCompany()
  const social = useSocial()

  footer.map(footerItem => {
    footerItem.treeChildren.sort((a, b) => a.position - b.position)
    footerItem.treeChildren.map(footerItem => {
      if (footerItem.treeChildren.length > 0) {
        footerItem.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })

  return (
    <Box
      id="contact-footer-section"
      as="div"
      sx={{
        position: "relative",
        backgroundColor: "primary",
        py: [2, 6],
        pb: [0, 0, 0, 0],
        a: { color: "dark" },
        zIndex: 2,
      }}
    >
      <i18nContext.Consumer>
        {t => (
          <>
            <Container sx={{ mb: [0, 0, 0, 0], pb: [0, 0, 0, 0] }}>
              <Grid
                columns={[1, footer.length]}
                gap={[2, 10]}
                sx={{ alignItems: "center", position: "relative" }}
              >
                <Box>
                  <Box
                    sx={{
                      mt: [0, 0, 0],
                      p: {
                        fontSize: 2,
                        color: "dark",
                        em: {
                          fontStyle: "normal",
                          fontSize: [6, 7],
                          fontFamily: "heading",
                          lineHeight: "heading",
                          fontWeight: "heading",
                          color: "light",
                          mt: [6, 6, 6],
                        },
                      },
                      ".contact-arrow": {
                        py:[3],
                        display: breakpointIndex > 0 && "none!important",
                        a: {
                          color: "light",
                        },
                      },
                      "*:first-child": { mt: 0 },
                    }}
                    dangerouslySetInnerHTML={{ __html: company.body }}
                  />
                  <Box>
                    <Flex
                      sx={{
                        my: [5],
                        mb: [0, 0, 0, 0],
                        pb: [0, 0, 0, 0],
                        flexWrap: "wrap",
                        "> div": { mx: [0, 0], mr: [2, 2] },
                      }}
                    >
                      {social.facebook && (
                        <Box>
                          <MagicLink target="_blank" href={social.facebook}>
                            <Facebook color="#FFF" />
                          </MagicLink>
                        </Box>
                      )}
                      {social.twitter && (
                        <Box>
                          <MagicLink target="_blank" href={social.twitter}>
                            <Twitter color="#FFF" />
                          </MagicLink>
                        </Box>
                      )}
                      {social.instagram && (
                        <Box>
                          <MagicLink target="_blank" href={social.instagram}>
                            <Instagram color="#FFF" />
                          </MagicLink>
                        </Box>
                      )}
                      {social.linkedin && (
                        <Box>
                          <MagicLink target="_blank" href={social.linkedin}>
                            <Image variant="avatar" src={LinkedinWhite} />
                          </MagicLink>
                        </Box>
                      )}
                      {social.youtube && (
                        <Box>
                          <MagicLink target="_blank" href={social.youtube}>
                            <Youtube color="#FFF" />
                          </MagicLink>
                        </Box>
                      )}
                    </Flex>
                  </Box>
                </Box>
                {footer.map(
                  (column, index) =>
                    index < 1 &&
                    column.treeChildren.length > 0 && (
                      <List key={column.id}>
                        {column.treeChildren.map(link => (
                          <Item>
                            <Text
                              sx={{
                                fontWeight: "bold",
                                mb: 2,
                                display: "block",
                              }}
                            >
                              {link.link ? (
                                <MagicLink
                                  item={link.link}
                                  sx={{ color: "light" }}
                                >
                                  <Flex sx={{ alignItems: "center" }}>
                                    {index === 0 && (
                                      <LongArrowRight color="light" />
                                    )}
                                    <Box
                                      sx={{
                                        ml: 3,
                                        color: index === 0 && "light",
                                      }}
                                    >
                                      {link.anchor}
                                    </Box>
                                  </Flex>
                                </MagicLink>
                              ) : (
                                link.anchor
                              )}
                            </Text>
                            {link.treeChildren.length > 0 && (
                              <List key={link.id}>
                                {link.treeChildren.map(link => (
                                  <Item>
                                    {link.link ? (
                                      <MagicLink item={link.link}>
                                        <Box sx={{ ml: 3, color: "dark" }}>
                                          {link.anchor}
                                        </Box>
                                      </MagicLink>
                                    ) : (
                                      link.anchor
                                    )}
                                  </Item>
                                ))}
                              </List>
                            )}
                          </Item>
                        ))}
                      </List>
                    )
                )}
              </Grid>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

const List = props => {
  const breakpointIndex = useBreakpointIndex()
  return (
    <Flex
      {...props}
      sx={{
        display: breakpointIndex === 0 && "none!important",
        flexDirection: "row",
        margin: 0,
        padding: 0,
        mb: [4],
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return (
    <Box
      {...props}
      as="li"
      sx={{ mr: [4, 6], textTransform: "lowercase", "&:last-child": { mb: 0 } }}
    />
  )
}

export default ContactFooter
